.info-popover {
  top: 225px;
}

.info-popover .ant-popover-arrow {
  border-bottom-color: #fff !important;
  border-left-color: #fff !important;
}

.info-popover .ant-popover-inner {
  background: #fff;
}

.info-popover .ant-popover-title {
  border-bottom: none;
}

.info-popover .ant-popover-inner-content {
  color: #fff;
  max-width: 256px;
}
